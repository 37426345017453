import React from "react";
import { useRoutes } from "react-router-dom";
import About from "../About";
import Home from "../Home";
import Contact from "../Contact";
import NotFoundPage from "../NotFound/NotFoundPage";
import Docs from "../Docs";
import { Click } from "../Click";
import BackHome from "../BackHome";

const Routes = () => {
  let elements = useRoutes([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/about",
      element: <About />,
    },
    {
      path: "/contact",
      element: <Contact />,
    },
    {
      path: "/docs",

      children: [
        {
          index: true,
          element: <Docs />,
        },
        {
          path: "clickhere",
          element: <Click />,
        },
      ],
    },
    {
      path: "*",
      element: <NotFoundPage />,
    },
  ]);
  return elements;
};

export default Routes;
