import React from "react";
import styled from "styled-components";
// import Home from "./Home";
import { NavLink } from "react-router-dom";

const Header = () => {
  return (
    <Container>
      <Wrapper>
        <NavLink to="/" style={{ textDecoration: "none" }}>
          <div>Home</div>
        </NavLink>
        <NavLink to="/about" style={{ textDecoration: "none" }}>
          <div>About</div>
        </NavLink>
        <NavLink to="/contact" style={{ textDecoration: "none" }}>
          <div>Contact Us</div>
        </NavLink>
        <NavLink to="/docs" style={{ textDecoration: "none" }}>
          <div>Docs</div>
        </NavLink>
      </Wrapper>
    </Container>
  );
};

export default Header;

const Container = styled.div``;

const Wrapper = styled.div`
  width: 100%;
  height: 80px;
  background-color: blue;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-decoration: none;
  color: white;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;

  div {
    color: white;
    text-decoration: none;

    :hover {
      color: black;
    }
  }
`;
