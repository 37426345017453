import React from "react";
import { NavLink } from "react-router-dom";

const Docs = () => {
  return (
    <div>
      <NavLink to="/docs/clickhere">click here</NavLink>
    </div>
  );
};

export default Docs;
