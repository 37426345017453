import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <Container>
      <Wrapper>
        <Number>404 !</Number>
        <Page>Page Not Found </Page>
        <Long>
          The page doesn't exist.Try checking the url or checking the url or
          reloading the web page.
        </Long>
        <NavLink to="/" style={{ textDecoration: "none" }}>
          <Box>Back Home</Box>
        </NavLink>
      </Wrapper>
    </Container>
  );
};

export default NotFoundPage;

const Container = styled.div`
  /* height: 100%;
  width: 100%; */
`;
const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const Number = styled.div`
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 200px;
`;
const Page = styled.div`
  font-size: 30px;
  font-weight: 20px;
  padding: 10px;
`;
const Long = styled.div`
  padding-bottom: 20px;
`;
const Box = styled.div`
  height: 50px;
  width: 100px;
  background-color: blue;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  :hover {
    color: black;
  }
`;
