import React from "react";
import Header from "./Components/Header";
// import { Router } from "react-router-dom";
import { useRoutes } from "react-router-dom";
import Home from "./Components/Home";
import About from "./Components/About";
import Contact from "./Components/Contact";
import Routes from "./Components/AllRoutes/Routes";

const App = () => {
  return (
    <div>
      <Header />
      <Routes />
    </div>
  );
};

export default App;
